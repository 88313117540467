import React, { Component } from 'react'
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Hidden,
  Drawer,
  Popover,
  MenuItem,
} from '@material-ui/core'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { withRouter } from 'react-router-dom'
import { getRequest } from '../../Services/FetchFunctions'
//import { Link } from "react-scroll";

const stylesIndex = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
})

const themeAppBar = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: ' 0px 1px 0px #E5E9F2',
      },
    },
    MuiButton: {
      root: {
        marginRight: '15px',
        fontFamily: 'inherit',
        color: '#252B33 !important',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: 'none !important',
        border: '2px solid #ffffff !important',
        position: 'relative',
        '&:hover': {
          backgroundColor: 'none !important',
        },
        '&:focus': {
          backgroundColor: 'none !important',
        },
      },
    },
    MuiMenuItem: {
      root: {
        color: '#0B0B0B',
        fontFamily: 'inherit',
        fontSize: '14px',
        padding: '15px',
        height: '45px',
        fontWeight: '600',
        borderBottom: '1px solid #DDE0E2A8',
        '&:hover': {
          backgroundColor: 'rgba(26, 127, 232, 0.09) !important',
        },
        '&$selected': {
          backgroundColor: ' #f5f9fa',
        },
      },
    },
    MuiDrawer: {
      paper: {
        width: '150px',
        alignItems: 'center',
        paddingTop: '50px',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(37, 43, 51, 0.5)',
        backdropFilter: 'blur(2px)',
      },
    },
  },
})

class AppMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      username: '',
      landingPageMenu: [
        // { id: "How", label: "How it works" },
        { id: 'Cars', label: 'Cars', path: '/Showroom' },
        { id: 'Partners', label: 'Partners' },
        // { id: "Contact", label: "Contact" },
        { id: 'Support', label: 'Support' },
      ],
      menu: localStorage.getItem('landingPageMenu') || '',
    }

    this.handleChange = this.handleChange.bind(this)
  }
  componentDidMount() {
    this.getUsername()
  }
  getUsername() {
    var url = 'client/get-details'
    getRequest(url)
      .then((resp) => {
        var nameUser = resp.data.firstName
        this.setState({
          username: nameUser,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  handleChange(menuItem) {
    this.setState({ menu: menuItem })
    this.props.history.push(`/${menuItem}`)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  handleDrawerToggle = () => {
    if (this.state.mobileOpen) {
      this.setState({ mobileOpen: false })
    } else {
      this.setState({ mobileOpen: true })
    }
  }

  handleSelectedMenu(index) {
    this.setState({ menu: this.state.landingPageMenu[index].id })
    localStorage.setItem(
      'landingPageMenu',
      this.state.landingPageMenu[index].id
    )
    this.props.history.push(this.state.landingPageMenu[index].path)
  }
  handleLogout() {
    localStorage.clear()
    setTimeout(() => this.props.history.push('/'), 2000)
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={themeAppBar}>
          <AppBar position="fixed">
            <Toolbar>
              <div className="DisplayFlex1" style={{ flexGrow: 1 }}>
                <img
                  alt="JAVA DRIVE SAFE"
                  src="./icon/jsd-logo.png"
                  style={{
                    marginLeft: '15%',
                    cursor: 'pointer',
                    width: '10rem',
                  }}
                  onClick={() => {
                    this.props.history.push('/')
                    localStorage.setItem('landingPageMenu', 'How')
                  }}
                />
              </div>

              <Hidden smUp implementation="css">
                <IconButton
                  edge={false}
                  onClick={() => this.handleDrawerToggle()}
                  className={classes.menuButton}
                  style={{ color: '#000000' }}
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  variant="temporary"
                  anchor="right"
                  open={this.state.mobileOpen}
                  onClick={() => this.handleDrawerToggle()}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  {/* <Link
                    activeClass="active"
                    className="landingPageButton"
                    to="HowItWorks"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  > */}
                  <Button
                    color="inherit"
                    className={
                      this.state.menu === 0
                        ? 'LandingPageSelected'
                        : 'landingPageButton'
                    }
                  >
                    How it works
                  </Button>

                  {this.state.landingPageMenu.map((menu, index) => (
                    <Button
                      color="inherit"
                      key={index}
                      className={
                        this.state.menu === menu.id
                          ? 'LandingPageSelected'
                          : 'landingPageButton'
                      }
                      onClick={() => this.handleSelectedMenu(index)}
                    >
                      {menu.label}
                    </Button>
                  ))}

                  {this.state.username !== '' ? (
                    <PopupState variant="popover" popupId="demoPopover">
                      {(popupState) => (
                        <div>
                          <div
                            style={{
                              marginBottom: '0px',
                              marginRight: '10%',
                            }}
                            className="avatarDiv"
                            {...bindTrigger(popupState)}
                          >
                            <span>{this.state.username}</span>

                            <img
                              alt="dropdown"
                              src="/icon/dropdown.svg"
                              style={{ height: '1.5em', marginLeft: '10px' }}
                            />
                          </div>
                          <Popover
                            {...bindPopover(popupState)}
                            style={{
                              marginTop: '6px',
                              height: '200px',
                              paddingLeft: '15px',
                              paddingRight: '15px',
                            }}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <div style={{ padding: '0 20px 0 20px' }}>
                              <MenuItem
                                value="viewProfile"
                                onClick={() =>
                                  this.props.history.push('/Dashboard')
                                }
                              >
                                Go to Dashboard
                              </MenuItem>
                              <MenuItem
                                value="Logout"
                                onClick={() => this.handleLogout()}
                              >
                                Logout
                              </MenuItem>
                            </div>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  ) : (
                    <>
                      <button
                        className="PurpleButton"
                        style={{ margin: '10px 0 20px 0', width: '100px' }}
                        onClick={() => this.props.history.push('/Login')}
                      >
                        Login
                      </button>
                      <button
                        className="SignUpFormsSubmit"
                        style={{ width: '100px' }}
                        onClick={() => this.props.history.push('/Signup')}
                      >
                        Get started
                      </button>
                    </>
                  )}
                </Drawer>
              </Hidden>
              <Hidden xsDown>
                <Button
                  color="inherit"
                  className={
                    this.state.menu === 0
                      ? 'LandingPageSelected'
                      : 'landingPageButton'
                  }
                  // onClick={() => this.handleSelectedMenu(0)}
                >
                  How it works
                </Button>

                {this.state.landingPageMenu.map((menu, index) => (
                  <Button
                    color="inherit"
                    key={index}
                    className={
                      this.state.menu === menu.id
                        ? 'LandingPageSelected'
                        : 'landingPageButton'
                    }
                    onClick={() => this.handleSelectedMenu(index)}
                    component="button"
                  >
                    {menu.label}
                  </Button>
                ))}
                {this.state.username !== '' ? (
                  <PopupState variant="popover" popupId="demoPopover">
                    {(popupState) => (
                      <div>
                        <div
                          style={{
                            marginBottom: '0px',
                            marginRight: '10%',
                          }}
                          className="avatarDiv"
                          {...bindTrigger(popupState)}
                        >
                          <span>{this.state.username}</span>

                          <img
                            alt="dropdown"
                            src="/icon/dropdown.svg"
                            style={{ height: '1.5em', marginLeft: '10px' }}
                          />
                        </div>
                        <Popover
                          {...bindPopover(popupState)}
                          style={{
                            marginTop: '6px',
                            height: '200px',
                            paddingLeft: '15px',
                            paddingRight: '15px',
                          }}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <div style={{ padding: '0 20px 0 20px' }}>
                            <MenuItem
                              value="viewProfile"
                              onClick={() =>
                                this.props.history.push('/Dashboard')
                              }
                            >
                              Go to Dashboard
                            </MenuItem>
                            <MenuItem
                              value="Logout"
                              onClick={() => this.handleLogout()}
                            >
                              Logout
                            </MenuItem>
                          </div>
                        </Popover>
                      </div>
                    )}
                  </PopupState>
                ) : (
                  <>
                    <button
                      className="PurpleButton"
                      style={{ marginRight: '30px', width: '62px' }}
                      onClick={() => this.props.history.push('/Login')}
                    >
                      Login
                    </button>
                    <button
                      className="SignUpFormsSubmit"
                      style={{
                        marginBottom: '0px',
                        width: '100px',
                        marginRight: '10%',
                      }}
                      onClick={() => this.props.history.push('/Signup')}
                    >
                      Get started
                    </button>
                  </>
                )}
              </Hidden>
            </Toolbar>
          </AppBar>
        </MuiThemeProvider>
      </div>
    )
  }
}

export default withRouter(withStyles(stylesIndex)(AppMenu))
